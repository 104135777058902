.ekskursii{
	margin-right:15px;
	margin-left:15px;
	// margin-top:100px;
	padding-bottom:160px;
}

// .exc__subtitle {
//     font-size: 26px;
//     font-weight: 300;
//     text-align: center;
//     max-width: 600px;
//     margin: auto;
//     margin-bottom: 80px;
// }


// .exc__item-wrap{
// 	margin-bottom: 80px;
// }

.exc__item{
	// margin-left:40px;
	position:relative;
	margin-right:10px;
	margin-left: 10px;
	margin-bottom:45px;
	border-radius:25px;
	overflow:hidden;
}

// .exc__item a{
// 	position:relative;
// }
// .exc__item a:hover:before{
// 	opacity:.2;
// 	transition: $mytransition;
// }

.exc__img-wrap{
	display: flex;
    align-items: center;
    position:relative;
    transition: $mytransition;
	
	&:before{
	    content: "";
	    display: block;
	    position: absolute;
	    top: 0;
	    left: 0;
	    right: 0;
	    bottom: 0;
	    background: #000;
	    opacity:0;
	    transition: $mytransition;
	}
	&:hover:before{
		opacity:.3;
		transition: $mytransition;
	}
	&:after{
		content:"";
		position: absolute;
		bottom:0;
		left:0;
		width:100%;
		height:50%;
		background: linear-gradient(180deg,hsla(0,0%,100%,0),rgba(0,0,0,.3));
	}


}

.exc__img-wrap:hover .exc__img-wrap:before{
background:red;
}

.exc__item:hover .exc__button{
	padding-right:10px;
	transition: $mytransition;
}



// .exc__item:hover {
// 	& .exc__title{
// 		color: #f6c30e;
// 		transition:all 0.3s ease-out;
// 	}
// 	& .exc__button{ 
// 		opacity:1;
// 		transition:all 0.3s ease-out;
// 	}
// 	& .exc__img-wrap img{
// 		transform:scale(1.1);
// 		transition:all 1s ease-out;
// 	}
// }

.exc__text{
	position:absolute;
	left:0;
	right:0;
	bottom:0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	transition: $mytransition;
	padding:20px;
}

// .exc__text:hover .exc__img-wrap:before{
// 	opacity:.2;
// 	transition: $mytransition;
// }

.exc__title {
	max-width: 75%;
	display: inline-block;
	font-size: 26px;
    font-weight: 600;
	color: #fff;
	line-height:1.3;
	transition:all 0.3s ease-out;
}

.exc__button {
    padding-right: 0px;
    color: #fff;
}

// .exc__details{
// 	height:60px;
// 	display:flex;
// 	justify-content: space-between;
// 	align-items:center;
// }

// .exc__duration{
// 	position: relative;
// 	padding-left:34px;
// 	color: $brightblue;
// 	font-size:16px;
// }

// .exc__duration:before{
// 	content:"";
// 	position: absolute;
// 	display: block;
// 	top:0;
// 	left:0;
// 	height:30px;
// 	width:30px;
// 	background:url("../img/icons/icon-time-span.png") center center no-repeat;
// 	background-size:contain;
// }

.exc__button{
	font-size:40px;
	padding-right:0;
	transition: $mytransition;
    // display: flex;
    // align-items: center;
    // opacity:0.6;
    // transition:all 0.3s ease-out;
}

.exc__row{
	display:flex;
	// justify-content: space-between;
	// align-items:center;
}


@media screen and (min-width: 576px){
	.exc__item-wrap{
		flex-basis:50%;	
	}
}

@media screen and (min-width: 768px){
	.exc__item-wrap{
		flex-basis:33%;	
	}
}

@media screen and (min-width: 992px){
	// .exc__img-wrap{
	// 	height:100%;
	// }

}

@media screen and (min-width: 1200px){
	.ekskursii{
		margin-right:40px;
		margin-left:40px;
		margin-top:0;
	}
	.exc__item-wrap{
		flex-basis:25%;	
	}
	.exc__item{
		margin-right:20px;
		margin-left: 20px;
		// flex-basis:500px;	
	}
	.exc__title{
		font-size:22px;
		letter-spacing: 0;
	}
	// .exc__details{
	// 	height:90px;
	// }
	// .exc__duration{
	// 	padding-left:44px;
	// 	font-size:20px;
	// }
	// .exc__duration:before{
	// 	height:40px;
	// 	width:40px;
	// }
	// .exc__img-wrap{
	// 	margin-bottom:90px;
	// }
}
