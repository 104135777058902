@import "./partials/normalize";
@import "./partials/variables";
@import "./partials/iconfont";
// @import "./partials/sprite";


html, body{
	height: 100%;
	width:100%;
	margin:0;
	padding:0;
}
html{
	overflow-x:hidden;
}

body{
	font-family: $fontbasic, sans-serif;
	font-size:20px;
	color: $textcolor;
	line-height:1.6;
	font-weight: 400;
	background: #fff;
	position:relative;
}

img{
	max-width: 100%;
}


// Убираем желтую обводку в Chrome у разных элементов
button, 
button:active, 
button:focus,
select, 
select:active, 
select:focus,
input, 
input:active, 
input:focus,
   {
    outline: none;
    border:none;
    box-shadow: none;
}

.css-place{
	display:none !important;
}
// Убираем пунктирную рамку у активных элементов
a.active.focus,
a.active:focus,
a.focus,
a:active.focus,
a:active:focus,
a:focus,
button.active.focus,
button.active:focus,
button.focus,
button:active.focus,
button:active:focus,
button:focus,
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
	outline: 0!important;
	outline-color: transparent!important;
	outline-width: 0!important;
	outline-style: none!important;
	box-shadow: 0 0 0 0 rgba(0,123,255,0)!important;
} 

// Убираем желтый фон в Chrome при автозаполнении (элементы формы)
@-webkit-keyframes autofill {
    to {
        color: $formcolor;
        background: #fff;
    }
}

input:-webkit-autofill {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
    -webkit-text-fill-color: $formcolor;
}


// Убираем стрелки с числового поля
input[type='number'] {
    -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

// End

a{
	display: block;
	text-decoration: none;
	transition: all 0.5s ease-out;
	color: $textcolor;
	position:relative;
}

a:hover {
	text-decoration: none;
	transition: all 0.5s ease-out;
}

.a-link{
	&:after{
		content:"";
		position:absolute;
		bottom:-3px;
		left:0;
		width:100%;
		height:1px;
		border-bottom:1px dashed $textcolor;
	}
	&:hover{
		// border-bottom:1px solid $textcolor;
		opacity:.7;	
	}
}
// .a-link:hover{
// 	opacity:0.5;
// }

ul, li{
	padding:0; 
	margin:0;
	display: block;
}

label{
	line-height: 1.2;
}


h1, h2, h3, h4 {
	// font-family: "Noto Serif", serif;
	color: $headercolor;
	text-transform: uppercase;
	// font-weight: 400;
}

h1{
	font-size: 36px;
	letter-spacing: 2px;
	font-weight: 800;
	line-height:1.15;
	margin-top:0;
	margin-bottom: 50px;
	position:relative;
}

h2, h3{
	line-height:1.3;
	font-weight: 800;
}

h2, .h2{
	// font-size:40px;
	font-size: 28px;
	margin-top: 0;
	margin-bottom: 40px;
}

h3{
	font-size: 22px;
	margin-bottom: 40px;
}

h4{
	line-height:1.3;
}

.container-fluid{
	max-width: 1566px;
}

.text-colored{
	color: $yellow;
}

.text-bold{
	font-weight: 700;
}

.text-red{
	// color: #b90707;
	color: #fc2020;
}

.header-colored{
	display:block;
}

.subtitle{
	margin-top:-20px;
	margin-bottom:30px;
	opacity:.6;
}


.icon:before{
	padding-right:8px;
	vertical-align: bottom; //- когда иконки - строчные элементы, то требуется опустить их вниз, чтобы не висели
}

.nowrap{
	white-space: nowrap;
}

.no-padding{
	padding:0;
}


.btn {
    display: inline-block;
    border:none;
    // border-radius:100px;
    text-align: center;
    // background: $yellow;
    font-size:14px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #fff;
    // padding:18px 32px;
    padding: 14px 28px;
    border-radius: $radius-sm;
}

.btn--cta{
  height:50px;
  background: #222;
  color:#f2f2f2;
  width:100%;
  transition: $mytransition;
  position:relative;
  &:hover{
  	background: $textcolor;
  	box-shadow: 0 10px 30px rgba(0,0,0,.2);
  	transition: $mytransition;
  }
}

.btn--back{
	color:#555;
	background:none;
	font-size:14px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    transition:$mytransition;
    margin-right: 20px;
	&:before{
		content:"←";
		font-size:20px;
		padding-right:8px;
		transition:$mytransition;
	}
	&:hover{
		color:#ec9f0b;
		transition:$mytransition;
	}
	&:hover:before{
		padding-right:4px;
		padding-left:4px;
		transition:$mytransition;
	}
}

.btn--round{
	position: relative;
	z-index:2;
	overflow:hidden;
	display: block;
}

.btn--round{
	border-radius: 100%;
	color: $textcolor;
	border:1px solid $textcolor;
	text-align:center;
	&:before{
		display: block;
		padding:0;
	}
}

.btn--round:after{
	border-radius: 100%;
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	background: $textcolor;
	transition-duration: 0.4s;
	transform: translate(-100%, -100%);
}

.btn--round:hover{
    color: #fff;
    transition-duration: 0.4s;
}

.btn--round:hover::after {
    transform: translate(0, 0);
}

.btn.focus, .btn:focus {
    box-shadow: none;
}

.section-padding{
	padding:80px 0;
}

.section-padding-top{
	padding:80px 0 0 0;
}

.box-text{
	max-width:420px;
	margin:auto;
	margin-bottom:30px;
}

.img-center{
	display: block;
	margin:auto;
}

.notmain{
	position:relative;
	background:url('../img/bg-notmain.svg') top right no-repeat;
	background-size:50% auto;
}

@media screen and (min-width: 576px){
	.btn {
      min-width:200px;
    }
	.section-padding{
		padding:140px 0;
	}
	.section-padding-top{
		padding:140px 0 0 0;
	}
}

@media screen and (min-width: 768px){
	h1{
		margin-bottom: 80px;
	}
	h2, .h2{
		font-size:36px;
		margin-bottom: 60px;
	}
	h3{
		font-size: 28px;
	}
	.subtitle{
		margin-top:-30px;
		margin-bottom:50px;
	}
}

@media screen and (min-width: 992px){
	h1{
		font-size: 65px;
	}
	h2, .h2{
		font-size:42px;
	}
}



/* ////////////////////////////////////////// */
/*-------------- PRELOADER--------------*/
/* ////////////////////////////////////////// */

#preload {
	position: absolute;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #555;
	z-index: 999999;
}

#over {
	width: 200px;
	height: 200px;
	position: absolute;
	left: 50%;
	top: 50%;
	background-image: url('../img/preloader.gif');
	background-repeat: no-repeat;
	background-position: center;
	margin: -100px 0 0 -100px;
}

/*-------------- End Preloader-----------------*/


/* ////////////////////////////////////////// */
/*-------------- POINTS--------------*/
/* ////////////////////////////////////////// */

.form-booking-excursions,
.step2,
{
	position:relative;
	&:before {
	    content: url('../img/points1.svg');
	    position: absolute;
	    z-index: -1;
	    top: -80px;
	    left: auto;
	    right: -80px;
	    height: 200px;
	    width: 200px;
	    display: block;
	    border-radius: 100%;
	    overflow: hidden;
	}
}

@media screen and (min-width: 576px){
	h1:before{
		content: url('../img/points1.svg');
		position: absolute;
		z-index: -1;
		left: -150px;
		top: -100px;
		overflow: hidden;
		height: 140px;
	}
	.one-excurs h1:before{
	    top: 140px;
	    left: auto;
	    right: -100px;
	}
}

@media screen and (min-width: 992px){
	h1:before{
		top: -120px;
		height: 180px;
	}
	.one-excurs h1:before{
	    top: 180px;
	    left: auto;
	    right: -100px;
	    height:auto;
	}
}

.offers__h2:before{
    top: 20px;
    left: auto;
    right: -100px;
}


/*-------------- End Points-----------------*/


/* ////////////////////////////////////////// */
/*-------------- MENU--------------*/
/* ////////////////////////////////////////// */

.navbar{
	z-index:1000;
	width:100%;
	display:flex;
	width:100%;
	justify-content: space-between;
	align-items:center;
	padding:20px 15px;
	color:#fff;
}

.navbar--absolute{
	position:absolute;
}

.navbar--relative{
	position:relative;
}

.navbar--dark{
	position:absolute;
}

.header__left,
.header__right {
	display:flex;
	align-items:center;
}

.header__right{
	width:100%;
	justify-content: flex-end;
}

.header__left {
    margin-right: 40px;
    background-size:contain;
}

.header__group{
	padding-left:30px;
	font-size: 14px;
	letter-spacing:1px;
	opacity:.6;
	line-height: 1.1;
	width:155px;
	display:none;
}

.header__logo{
  display: flex;
  height: 100%;
  align-items: center;
}

.header__logo {
    width: 160px;
    height: 40px;
    background: url("../img/logo.svg") no-repeat center left;
    background-size: contain;
    position: relative;
}

.menu__logo{
	width:64px;
	max-width:auto;
}

.menu{
	visibility: hidden;
	opacity: 0;
	display:flex;
	flex-direction:column;
	position:fixed;
	z-index:1;
	top:0;
	right:-100%;
	height:100%;
	justify-content: flex-end;
	align-items: center;
	letter-spacing: 1px;
}

.menu-list{
	display:flex;
	flex-direction:column;
	justify-content: space-around;
	align-items: center;
	min-width: 485px;	
}

.menu-list li{
	margin-right:40px;
}

.nav-link{
	color: $textcolor;
	position:relative;
	cursor:pointer;
}

.menu__phone{
	padding-right:6px;
	font-size: 20px;
	letter-spacing:0;
	font-weight: 600;
	&:before{
		padding-right:3px;
		line-height:1.4;
	}
}

.menu__contacts{
	display:flex;
	justify-content: space-between;
	align-items: center;
	margin-right:40px;
	&__icons{
		display: flex;
		justify-content: space-around;
		align-items:center;
		width: 160px;
	}
	&__icons img{
		display: block;
		width:auto;
		margin:auto;
		height:30px;
		padding-right:0;
		padding-left:5px;
	}
}

.lang{
	color: $textcolor;
	position:relative;
	cursor:pointer;
}

.lang__submenu{
	position:absolute;
	right:0;
	top:calc(100% + 8px);
	width:100px;
	border-radius: $radius_sm;
	background:rgba(255,255,255,0.1);
	padding:10px;
	opacity: 0;
	visibility: hidden;
	transition: opacity .15s ease,visibility .15s ease,transform .15s ease;
	right:0;
	transform: translate(0,30px);
}

.lang__submenu:before { //- треугольник-указатель, часть фона подменю
	content: "";
	width:6px;
	height:6px;
	display:block;
	top:-6px;
	right:10px;
	position:absolute;
    border-style: solid;
    border-width: 0 6px 6px 6px;
    border-color: transparent transparent $formbg transparent;
}

.lang-link {
    display: flex;
    align-items:center;
    margin-left:auto;
}

.lang__submenu img{
	width:26px;
	margin-right:10px;
}

.lang{
	display:flex;
	position:relative;
	font-size:14px;
	align-items:center;
	transition: $mytransition;
	&:before{
		font-size:20px;
	}
	&:hover:after{
    border-width: 0 4px 4px 4px;
    border-color: transparent transparent #222 transparent;
		transition: $mytransition;
	}
}

.lang:after { //- треугольник
	content: "";
	display: inline-block;
	opacity:1;
	width:4px;
	height:4px;
	margin:auto;
	background:transparent;
	position:relative;
	top:auto;
	left:auto;
    border-style: solid;
    border-width: 4px 4px 0 4px;
    border-color: #222 transparent transparent transparent;
    margin-left:3px;
    transition: $mytransition;
}


//- Menu styles for small screens
.menu--visible{
	display:flex;
	justify-content: center;
	padding-top:60px;
	visibility: visible;
	opacity:1;
	transition: right .4s ease-in-out;
	width: 100%;
	min-width: 320px;
	right:0;
	background: $yellow;
	& .menu-list{
		margin-bottom:20px;
		align-items:flex-start;
		min-width:320px;
		width:100%;
		padding-left:40px;
		padding-right:40px;
	}

	& .menu-list li{
		margin: 0 0 20px 0;
		width:100%;
		border-bottom:1px solid #353535;
	}
	& .nav-link:before{
		content: "";
		display: inline-block;
		opacity:0;
		width:6px;
		height:6px;
		background:transparent;
		position:relative;
		top:auto;
		left:auto;
	    border-style: solid;
	    border-width: 6px 0 6px 6px;
	    border-color: transparent transparent transparent #222;
	    margin-right:3px;
	    transition: $mytransition;
	}
	& .nav-link:hover:before{ //- треугольник
		opacity:1;
		margin-right:8px;
	    transition: $mytransition;
	}
	& .menu__contacts{
		flex-direction: column;
		margin-right:0;
		margin-bottom: 40px;
		align-items: flex-start;
		width:100%;
		padding-left:40px;
		padding-right:40px;
	}
	& .menu__contacts__icons{
		width: 180px;
	}
	& .menu__phone{
		font-size:24px;
		margin-bottom:12px;
	}
	& .lang{
		height:0;
		width:100%;
		padding-left:40px;
		padding-right:40px;
		display:flex;
		flex-direction: row;
	}
	& .lang:before{
		display:none;
	}
	& .lang:after{
		display:none;
	}
	& .lang:hover .lang__submenu{
		display:flex;
	}
	& .lang .active{
		width:70px;
	}
	& .lang__submenu{
		display:flex;
		position:static;
		opacity:1;
		visibility: visible;
		width:100%;
		background: rgba(255,255,255,0.3);
	}
	& .lang__submenu:before{
		display:none;
	}
	& .lang__text{
		color:$textcolor;
	}
	& .lang-link{
		width:80px;
	}
	& .lang__visible{
		display:none !important;
	}
	& .menu__cta{
		margin-left:0;
		margin-top:10px;
	}
}

.menu-button{
	width: 30px;
	height:30px;
	position:relative;
	background:transparent;
	border:none;
	padding:0;
	z-index:2;
}

.menu-button span{
	display: block;
	background: #222;
	opacity: 1;
}

.menu-button span{
	height: 2px;
	-webkit-transition: all .5s;
	-o-transition: all .5s;
	transition: all .5s;
	z-index: 2;
}

.navbar--light .menu-button span{
	background:$headercolor;
}

.menu-button--to-open span + span {
    margin-top: 7px;
}

.menu-button--to-open span{
  &:nth-child(1){
    width: 100%;
  }
  &:nth-child(2){
     width: 70%;
     margin-left:auto;
     margin-right:auto;
  }
  &:nth-child(3){
    width: 100%;
  }
}

.menu-button--to-close{
	position: fixed;
	right: 40px;
}

.menu-button--to-close span{
  &:nth-child(1){
	transform: rotate(45deg);
	z-index: 2
  }
  &:nth-child(2){
	opacity:0;
  }
  &:nth-child(3){
	margin-top:-4px;
	transform: rotate(-45deg);
  }
}

.navbar--light .menu-button--to-close span{
	background:#fff;
}

@media screen and (min-width: 576px){
	.subtitle{
		letter-spacing: 3px;
	}
	.menu--visible{
		& .menu-list{
			padding-left:100px;
			padding-right:100px;
		}
		& .menu__contacts{
			padding-left:100px;
			padding-right:100px;
		}
		& .lang{
			padding-left:100px;
			padding-right:100px;
		}
	}
}

@media screen and (min-width: 768px){
	.header__group{
		display:block;
	}
	.menu__cta{
	    padding: 12px 34px;
	    font-size: 16px;
	}
	.navbar{
		padding:20px 25px;
	}
	.navbar--light{
		padding:20px 40px;
	}
	.navbar--small{
		padding-top: 20px;
		padding-bottom: 20px;
	}
}

@media screen and (min-width: 1200px){
	.navbar{
		padding:20px 40px;
	}
	.navbar--light{
		margin-bottom:100px;
	}
	.nav-link{
		&:after{
			content: "";
			position:absolute;
			display: inline-block;
			opacity:0;
			width:6px;
			height:0;
			background:transparent;
			top:calc(100% + 3px);
			left:0;
			border-style: solid;
			border-width: 0 6px 6px 6px;
			border-color: transparent transparent  #222 transparent;
			// transform: translateX(50%);
			transition: $mytransition;
		}
		&:hover:after{ //- треугольник
			opacity:1;
			height:6px;
		    transition: $mytransition;
		}
	}
	.menu__logo{
		width:auto;
	}
	.header__logo {
	    width: 180px;
	    height: 50px;
    }
	.menu-button{
		display:none;
	}
	.menu{
		position:static;
		flex-direction: row;
		visibility: visible;
		background-color:transparent;
		opacity: 1;
		max-width:1150px;
		left:0;
		width:100%;
		font-size:14px;
		font-weight:600;
		letter-spacing: 0.12em;
		text-transform: uppercase;
		justify-content: space-between;
	}
	.menu-list{
		flex-direction: row;
	}
	.lang__submenu--visible{
		opacity: 1;
		visibility: visible;
		transform: translate(0,0);
	}
	.menu--visible .lang-link{
		width:70px;
	}
	.lang-link{
		margin-bottom: 10px;
	}
	.lang__submenu img{
		width:20px;
		margin-right:15px;
	}
	.lang__submenu{
		background: $formbg;
		box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
	}
}

/*--------------MENU END------------------*/



/* ////////////////////////////////////////// */
/*-------------- HOME--------------*/
/* ////////////////////////////////////////// */

.home-main{
	position: relative;
	width:100%;
	height: auto;
	padding:100px 0 80px 0;
	overflow-x:hidden;
	&:before{
		content:"";
		display: block;
		position:absolute;
		left:25%;
		right:0;
		top:0;
		width:100%;
		height:100%;
		background: url('../img/bg-home.svg') 0 0 no-repeat;
		background-size: 100% auto;
	}
	&:after{
		content:"";
		display: block;
		position:absolute;
		left:0;
		top:350px;
		width:100%;
		height:400px;
		background: url('../img/bg-home-left.svg') 0 0 no-repeat;
		background-size: 100% auto;
		z-index:-1; //для доступности кнопок в форме
	}
}


.home-main__logo{
	margin-bottom:40px;
}

.home-main__content{
	display: flex;
	flex-direction: column;
	justify-content:center;
	padding-top:30px;
	padding-bottom:80px;
	max-width:100%;
	line-height:1.3;
}

.home-main__h1{
	position:relative;
	z-index:0;
	margin-bottom:30px;
	&:before{
		height:0;
	}
}

h2{
	position:relative;
	z-index:0;
}

h1{
	position: relative;
	&:after{
		content:"";
		display:block;
		margin-top:20px;
		width:80px;
		left:0;
		height:10px;
		background: $yellow;
		z-index:-1;
	}
}

.home-main__subtitle{
	font-size:14px;
	letter-spacing: 0.5rem;
	text-transform:uppercase;
	margin-bottom: 40px;
	z-index:1;	
}

.home-main__description{
	font-size: 20px;
	color: $textcolor;
	line-height: 1.6;	
}

.text-date{
	font-weight: 700;
}

.year{
	color: $orange1;
	font-weight: 700;
}

.home-main__btn{
	font-size: 12px;
	letter-spacing: 0.33em;
	color: $orange1;
	text-transform: uppercase;
	font-weight: 700;
	margin-top: 20px;
	display: flex;
	align-items:center;
	width:50px;
}

.home-main__circle{
	height:50px;
	width:50px;
	line-height: 50px;
	font-size:18px;
	&:before{
		width:20px;
		margin:auto;
	}
}

.mainform {
	position:relative;
	&:before{
		content: url('../img/points3.svg');
		position: absolute;
		z-index: -1;
		top: -50px;
		right: -100px;
		width:280px;
		height:150px;
		overflow:hidden;
	}
}

@media screen and (min-width: 576px){
	.home-main{
		padding-bottom:160px;
		&:before{
			left:25%;
		}
		&:after{
			top:490px;
		}		
	}
	.home-main__content{
		padding-bottom:100px;
		padding-top:100px;
	}
	.home-main__logo{
		max-width: 400px;
	}
	.home-main__h1{
		font-size:55px;
		&:before {
			content:url('../img/points1.svg');
			position: absolute;
			z-index:-1;
			top:-100px;
			left:-250px;
			height:auto;
		}
	}
}

@media screen and (min-width: 768px){
	.home-main:before{
		left:calc(60% - 200px);
	}
	.home-main__logo{
		max-width: 450px;
	}
	.home-main__content{
		display: flex;
		flex-direction: column;
		justify-content:center;
		padding: 80px 0 100px 0;
	}
	.home-main__btn{
		font-size: 14px;
		width:60px;
	}

	.home-main__circle{
		height:60px;
		width:60px;
		line-height: 60px;
	}
}

@media screen and (min-width: 992px){
	.home-main{
		padding-top:200px;
		height:800px;
		display: flex;
		align-items:center;
		overflow:visible;
		&:before{
			left:calc(50% - 400px);
			height:2000px;
		}
		&:after{
			height:2000px;
		}
	}
	.home-main__content{
		height:100%;
		padding:0;
	}
	.home-main__logo{
		max-width: 500px;
	}
	.home-main__subtitle{
		font-size:18px;
	}
	.home-main__description{
		font-size: 26px;
	}
}



@media screen and (min-width: 1200px){
	.home-main{
		padding-bottom:200px;
		height:100%;
		min-height:900px;
		max-height:1080px;
		&:before{
			height:1060px;
			width:auto;
			background-size: auto 100%;
			left:calc(60% - 570px);
		}
		&:after{
			background-size: auto;
		}
	}
	.home-main__h1{
		font-size:65px;
	}
	.home-main__content{
		padding:0;
	}
	.home-main__logo{
		max-width:720px;
	}
}

@media screen and (min-width: 1921px){
	.home-main:before{
		background: url('../img/bg-home-2560.svg') 0 0 no-repeat;
		background-size: 100% auto;
	}
}

/*-------------- END HOME MAIN -----------------*/



/* ////////////////////////////////////////// */
/*----------------- OFFERS -------------------*/
/* ////////////////////////////////////////// */

.offers{
	position:relative;
	// padding-top:0;
	padding-bottom:20px;
	overflow-x:hidden;
}

.offers__h2{
	position:relative;
	&:before{
		content: url('../img/points3.svg');
		position: absolute;
		z-index: -1;
	}
}

.offers__card{
	background: #fff;
	border-radius: 25px;
	margin-bottom: 30px;
	overflow:hidden;
	max-width: 362px;
	height:calc(100% - 30px);
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.11);
	margin-left: auto;
	margin-right: auto;
}

.offerswinery{
	position:relative;
	&:before{
		content:"";
		display:block;
		height:240px;
		width:100%;
		background:url("../img/offers/winery-big.jpg") center center no-repeat;
		background-size:cover;
	}
}

.offerswinery__wrap{
	position:relative;
	padding-bottom:120px;
	&:before{
		content:"";
		display:block;
		width:calc(100% - 30px);
		// height:340px;
		// background:url("../img/offers/winery-big.jpg") center center no-repeat;
		// background-size:cover;
		margin-left:15px;
		margin-bottom:40px;
		border-top:1px solid #343434;
		// border-top-left-radius:25px;
		// border-top-right-radius:25px;
	}
}

.offerswinery__imgwrap{
	border-top-left-radius:25px;
	border-top-right-radius:25px;
	overflow:hidden;
	margin-bottom:30px;
}

.offers__card_st1 {
    background: #fff;
    border-radius: 25px;
    margin-bottom: 30px;
    margin-left: auto;
	margin-right: auto;
    overflow: hidden;
    max-width:362px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.11);
}

.offers__card__img{
	height:180px;
	overflow:hidden;
}

.offers__card_st1 img{
	max-width:none;
	height:100%;
	width:auto;
}

.offers__card__offerline {
	display: flex;
	justify-content: space-between;
	align-items:center;
	min-width:240px;
}

.offers__card__offerline:not(:last-child){
	margin-bottom: 10px;
	padding-bottom:10px;
	border-bottom:1px solid #DFDFDF;
}

.offers__card__content{
	padding:28px 30px 30px;
	color: $textgrey;
	font-size: 16px;
}

.offers__card__title{
	color: $headergrey;
	font-size: 20px;
	line-height: 1.3;
	margin-bottom: 15px;
	& span{
		font-weight: 700;
		display: block;
	}
}

.offers__card__subtitle{
	color: $headergrey;
	font-size: 16px;
	line-height: 1.3;
	margin-bottom: 30px;
	font-weight: 700;
}

.offers__card__description{
	position:relative;
	color: #343434;
	font-size: 16px;
	line-height: 1.3;
	padding:8px 0;
	margin:10px 0;
	z-index:1;
	&:before{
		content:'';
		position:absolute;
		top:0;
		left:-30px;
		bottom:0;
		right:0;
		background: #FFF3DD;
		z-index:-1;
	}
}

.offers__card__title-st1{
	padding: 28px 30px 0 30px;
}

.offers__card__npersons{
	line-height: 1.4;
	width:120px;
}

.offers__card__npersons span{
	color: $red;
	font-weight: 700;
}

.offers__card__price{
	color: $yellow;
	font-weight: 600;
	font-size: 24px;
	text-align: right;
	letter-spacing:-1px;
}

.offers__card__btn{
	height:30px;
	width:30px;
	border-radius: 100%;
	text-align: center;
	&:before {
		padding-right: 0;
		display: block;
		height: 30px;
		width: 30px;
		line-height: 30px;
		padding-left: 0px;
		padding-bottom:1px;
		color: $textcolor;
	}
	&:hover:before{
		color: #fff;
		transition:0.4s;
		cursor: pointer;
	}
}

@media screen and (min-width: 576px){
	// .offers{
	// 	padding-bottom: 60px;
	// }
	.offers__card__npersons{
		font-size: 18px;
		width:auto;
	}
}

@media screen and (min-width: 768px){
	.offers{
		padding-bottom:60px;
	}
	.offerswinery:before{
		background:none;
		display:none;
	}
	.offerswinery__wrap{
		&:before{
			height:340px;
			background:url("../img/offers/winery-big.jpg") center center no-repeat;
			background-size:cover;
			margin-bottom:30px;
			border-top:none;
			border-top-left-radius:25px;
			border-top-right-radius:25px;
		}
	}
	.offers__card{
		margin-left: 0;
		margin-right: 0;
	}
	.offers__card_st1 {
		margin-left: 0;
		margin-right: 0;
	    max-width:none;
	}

}

@media screen and (min-width: 992px){
	.offers{
		padding-top:160px;
	}
	.offers__card__npersons{
		width:120px;
	}
	.offers__card__price{
		margin-right: 8px;
	}
}

@media screen and (min-width: 1200px){
	.offers__card__img{
		height:auto;
	}
	.offers__card_st1 img{
		max-width:100%;
		height:auto;
	}
	.offers__card__npersons{
		width:auto;
	}
	.offers__card__price{
		margin-right: 0;
		font-size: 26px;
	}
	.offers__card__content{
		font-size: 18px;
	}
}

@media screen and (min-width: 1921px){
	// .offers:before{
	// 	background: url('../img/offers-wave-2560.svg') top center no-repeat;
	// 	background-size:100%;
	// }
}

/*-------------- END OFFERS -----------------*/


/*-------------- Taximeeting -----------------*/

.taximeeting__container{
	position:relative;
	& h2{
		position: relative;
	}
	// & h2:before{
	// 	content:url('../img/points3.svg');
	// 	position: absolute;
	// 	z-index:-1;
	// 	top: -50px;
	// 	left: 50%;
 //    }
}

.taximeeting__text{
	color: $textcolor;
	margin-bottom: 30px;	
}

.taximeeting{
	position:relative;
	background: url('../img/bg-taxi.svg') 0 0 no-repeat;
	background-size:100% auto;
}


/*-------------- END taximeeting -----------------*/


/*--------------Banner Taormina Taxi Meeting -----------------*/

.banner{
	position: relative;
	z-index:0;
	border-radius: $radius;
    padding: 48px;
    text-align:center;
    background: url('../img/banner-tablet.jpg') no-repeat 50% 50%;
    background-size:cover;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    font-weight: 400;
    color: #fff;
    line-height:1.2;
    &:hover{
        color:#fff;
    }
    &:focus{
        color:#fff;
        text-decoration:none;
    }
}


#special-offers .banner__img{
    height:22px;
    width:auto;
    align-self:center;
    margin-bottom:25px;
}

.banner__line1, .banner__line2 span{
    font-family: inherit;
    font-size: 22px;
}

.banner__line2{
    font-family: inherit;
    font-weight: bold;
    letter-spacing:-1px;
    font-size: 28px;
    margin-top:10px;
    margin-bottom:25px;
}

.banner__line2 span{
    font-weight: normal;
}

.banner__price{
    font-family:inherit;
    font-weight:bold;
    font-size: 24px;
    margin-bottom:25px;
}

.banner__price span{
    color: #FF9D29;
    letter-spacing: -4px;
    font-size: 48px;
}

.banner__vantages{
    font-family:inherit;
    font-size: 22px;
    letter-spacing:1px;
}

.banner__vantages--rus{
    font-size: 18px;
}

.banner__button{
    display: block;
    max-width:200px;
    margin:auto;
    margin-top:20px;
    font-family: inherit;
    font-weight:bold;
    font-size:16px;
    color: #151D46;
    letter-spacing: 2px;
    background: #FF9D29;
    border:2px solid #FF9D29;
    border-radius: 50px;
    padding:5px 20px;
    &:hover{
        color: #FF9D29;
        background:transparent;
    }
}

@media only screen and (min-width: 768px) {
	.taximeeting h2:before{
		content: url('../img/points3.svg');
	    position: absolute;
	    top:250px;
	    right: -100px;
	    z-index:-1;
	}
    #special-offers .banner__img{
        height:26px;
    }
    .banner__line1, .banner__line2 span{
        font-family: inherit;
        font-size: 30px;
    }
    .banner__line2{
        font-size: 48px;
    }
}

@media only screen and (min-width: 992px) {
    .banner{
    	z-index:0;
        background: url('../img/banner-desktop.jpg') no-repeat 50% 50%;
    }
    .banner__button{
        display: inline-block;
        margin-left:10px;
        margin-top:0;
    }
}

@media only screen and (min-width: 1200px) {
    .taximeeting{
		background-size:auto;
		padding-top:280px;
    }
}

@media screen and (min-width: 1920px){
	.taximeeting{
		background: url('../img/bg-taxi-2560.svg') 0 0 no-repeat;
		background-size: 100% auto;
	}
}

/*--------------END banner -----------------*/

/* ////////////////////////////////////////// */
/*------------------ PARTNERS-----------------*/
/* ////////////////////////////////////////// */

.partners{
	position:relative;
	background: url('../img/bg-partners.svg') left top no-repeat;
}

.partner__item{
	background: #4d4c4c;
	border-radius: 10px;
	padding:40px;
	height:220px;
	display:flex;
	flex-direction: column;
	justify-content: space-between;
	align-items:center;
	text-align:center;
	color: #fff;
	font-size: 14px;
	line-height: 1.6;
	letter-spacing: 3px;
	text-transform: uppercase;
	margin-bottom: 30px;
	max-width:450px;
	transition: $mytransition;
	&:hover{
		background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0) 100%), #3A3A3A;
		transition: $mytransition;
	}
}

.partner__item__title span{
	display: block;
}

@media screen and (min-width: 768px){
	.partners h2:before{
		content: url('../img/points3.svg');
	    position: absolute;
	    top:0;
	    right: -100px;
	    z-index:-1;
	}
}

@media screen and (min-width: 992px){
	.partner__item{
		padding: 50px 50px;
		height: 240px;
	}
}

// @media screen and (min-width: 1200px){
// 	.partners{
// 		padding-top:370px;
// 		// &:before{
// 		// 	background: url('../img/taximeeting-wave.svg') top left no-repeat;
// 		// }
// 	}
// }

// @media screen and (min-width: 1921px){
// 	.partners{
// 		padding-top:340px;
// 		// &:before{
// 		// 	background: url('../img/taximeeting-wave-2560.svg') top left no-repeat;
// 		// }
// 	}
// }


/* ////////////////////////////////////////// */
/*-------------- PAGE FAQ--------------*/
/* ////////////////////////////////////////// */

.faq{
	position:relative;
	overflow: hidden;
}

.faq__row{
	align-items:center;
	margin-bottom: 60px;
}

.faq{
	position:relative;
}

.faq__img{
	display:none;
}

.faq__text{
	position:relative;
	&:before{
		content:"";
		background-size:contain;
		position: absolute;
		top:0;
		left:0;
		bottom:0;
		width:400px;
	}
}

.spoiler-wrap{
	padding:24px 40px 24px 40px;
	// border:1px solid rgba(255,255,255,.3);
	border:1px solid #d5d5d5;
	border-radius: $radius_sm;
	margin-bottom:20px;

	&.active{
		border:1px solid $orange1;	
	}
}


.spoiler-head{
	font-size: 20px;
	// padding-bottom: 15px;
	padding-right:30px;
	// padding-top:15px;
	position:relative;
	transition: all 0.5s ease-out;
	color:#111;
	&:before{
		font-size:24px;
		position:absolute;
		top:0;
		right:-20px;
		opacity:.6;
	}
	&:hover{
		transition: all 0.5s ease-out;
	}
}

.spoiler-body{
	padding-top:20px;
	padding-bottom:20px;
	padding-right:30px;
	opacity:.8;
	& p{
		margin:0;
	}
}

@media screen and (min-width: 768px){
	.faq__img{
		display:block;
	}
}

/*--------------END PAGE FAQ -----------------*/




/* ////////////////////////////////////////// */
/*-------------- CONTACTS--------------*/
/* ////////////////////////////////////////// */


.contacts{
	position:relative;
	overflow: hidden;
	// padding-top:200px;
	padding-bottom:120px;
 	flex-basis: 100px;
}

.contacts__text {
    margin-bottom: 60px;
}

.contacts__h1{
	text-align: left;
}

.contacts__phone{
	color:$headercolor;
	font-size: 26px;
}
.form-contacts {
    background: rgba(255,255,255,0.9);
    padding: 40px 20px;
    box-shadow: 0 0 20px rgba(15,67,129,.15);
}


// Общие свойства для форм

.form-group{
	display:flex;
	flex-direction:column;
	margin-bottom: 1rem;
}


.ajax-loader {
    display: none;
    margin:auto;
    margin-right: 15px;
}

// Стили при ошибке для элементов, которые нужно проверить (у которых класс form-control)
.was-validated .form-control:invalid,
.was-validated .form-control:invalid ~ label:before{
	display:block;
	border:1px solid red !important;
	// background: $errorbg;
}

// Стили checkbox при ошибке
.was-validated .form-control:invalid ~ .label-checkbox:before{
	background:#f9aeae;
}

.btn--send{
	width:100%;
}

.form-result{
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 18px;
  position: relative;
  display: none;
}

.result_error {
    color: red;
    background-color: #fbdddd;
    padding: 10px 30px 10px 20px;
}

.result_success {
    color: green;
    background-color: #e7f0e7;
    padding: 10px 30px 10px 20px;
    border: 1px solid green;
  }

.submit__wrap{
	display: flex;
	justify-content: space-between;
	align-items:center;
	flex-wrap:wrap;
	margin-top: 40px;
}

.contacts__phone-group{
	display: flex;
	flex-wrap: wrap;
	margin-top:40px;
	& i{
		font-size:24px;
	}
}

.contacts__phone{
	padding-right:10px;
	&:before{
		color:$textcolor;
		opacity:.6;
	}
}

.contacts__location{
	font-size:22px;
	margin-top:10px;
	margin-bottom: 40px;
	&:before{
		color:$textcolor;
		opacity:.6;
	}
}

.contacts-message{
	height:100px;
	max-width:100%;
}

.submit__wrap{
	justify-content:flex-end;
}

@media screen and (min-width: 576px){
	.form-contacts {
	    padding: 60px;
	}
}

@media screen and (min-width: 768px){
	.contacts{
		background: url('../img/bg-bottom-faq.jpg') bottom center no-repeat;
	}
}


/*--------------END CONTACTS -----------------*/



/* ////////////////////////////////////////// */
/*-------------- BOOKING--------------*/
/* ////////////////////////////////////////// */

input, select, textarea{
	padding:10px;
	font-size:18px;
	color: $formcolor;
	border-radius: $radius_sm;
	border:$formborder;
}

input, select{
	height:46px;
	width:100%;
}

textarea{
	max-width: 100%;
}

label{
	color: $labelcolor;
	font-weight: 400;
	font-size: 16px;
	margin-bottom: 8px;
}

// placeholder для select
[include*="form-input-select()"] select:not(:focus):invalid {
    color: #C7C7C7;
}

.form-simple{
	padding: 30px 20px;
	background: $yellowform;
	max-width: 400px;
	border-radius: $radius_sm;
	// box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
	box-shadow: 0 25px 50px 0 rgba(0,0,0,.15);
	& label{
		color: #4b4b4b; 
	}
	& input, select, textarea{
		border-color: transparent;
	}
	& .label-checkbox:before {
		border-color:transparent;
	}
}

.label-radio{
	font-size: 18px;
	color: #333;
	font-weight: 400;
}

.radio-item{
	position:relative;
	display: flex;
	align-items:center;
	margin-bottom: 8px;
	height:26px;
}

.radio {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.label-radio {
  position: relative;
  padding: 0 0 0 35px;
  cursor: pointer;
  margin-bottom:0;
}

.label-radio:before {
  content: '';
  position: absolute;
  top: 50%;
  margin-top:-11px;
  left: 0;
  width: 22px;
  height: 22px;
  // border: 1px solid $textvioletlight;
  border-radius: 50%;
  background: #FFF;
  border: $formborder;
}

.label-radio:after {
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -6px;
  left: 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: $yellow;
  opacity: 0;
  transition: .2s;
}

.radio:checked + label:after {
  opacity: 1;
}


.label-checkbox{
	// font-size: 18px;
	color: #333;
	margin-top:10px;
	// font-weight: 400;
}

.checkbox-item{
	position: relative;
	display: flex;
	align-items:center;
	margin-bottom: 8px;
	height:26px;
}

.checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.label-checkbox {
  position: relative;
  padding: 0 0 0 35px;
  cursor: pointer;
  margin-bottom:0;
}

.label-checkbox:before { //- Вид checkbox, квадратик
  content: '';
  position: absolute;
  top: 50%;
  margin-top:-11px;
  left: 0;
  width: 22px;
  height: 22px;
  background: #FFF;
  border-radius: $radius_sm;
  border:$formborder;
}

.label-checkbox:after {
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -6px;
  left: 4px;
  width: 8px;
  height: 14px;
  opacity: 0;
  transition: .2s;
	// border-color: $violet2;
  	border: 2px solid $textcolor;
	transform: rotateZ(40deg) translate(1px, -5px);
	border-top: 0px solid $textcolor;
	border-left: 0px solid $textcolor;
}

.checkbox:checked + label:after {
  opacity: 1;
}

.checkbox:checked + label:before {
  // background: $yellow;
}

.privacy__wrap{
	padding-top:20px;
	// padding-bottom:20px;
	// height: 100%;
	margin: auto;
	justify-content: center;
}

.privacy__wrap .checkbox-item{
	height:auto;
}
.privacy__wrap .label-checkbox{
	font-size:14px;
	line-height: 1.5;
}

.privacy__wrap .label-checkbox:before{
	top:0;
	margin-top:0;
}

.privacy__wrap .label-checkbox:after{
	top:0;
	margin-top:5px;
}

.privacy__text{
	font-size:14px;
	font-weight:400;
}

.link-privacy {
    display: inline;
    opacity:.8;
}

.link-privacy--light{
	color:#fff;
	opacity:.5;
	&:hover{
		opacity:1;
	}
}

.rules {
    top: 0;
    left: 0;
}


select {
  display: block;
  width: 100%; /* от ширины блока div */
  border-radius: $radius_sm;/* скругление полей формы */
  -webkit-appearance: none;/* Chrome */
  -moz-appearance: none;/* Firefox */
  appearance: none;/* убираем дефолтные стрелочки */
}


.select-wrap{
  position:relative;
  &:before{
    position:absolute;
    font-size: 22px;  
    line-height: 22px;
    top:50%;
    margin-top:-11px;
    padding:0;  
    right:10px;
    color: $iconsgrey;
    transform: rotate(90deg);
    // z-index:1;
    pointer-events: none;
    transition: transform 300ms ease-out;
  }
}

.select-arrow-rotation:before{
	transform: rotate(-90deg);
	transition: transform 300ms ease-out;
}

.destination-wrap{
	position:relative;
}

.btn-inverse{
	background: transparent;
	position:absolute;
	right:0;
	top:-7px;
	padding-right:0;
	&:hover .icon-arrow-inverse,
	&:active .icon-arrow-inverse,
	&:focus .icon-arrow-inverse{
		color: #666;
		transition:$mytransition;
	}
}

.icon-arrow-inverse{
	font-size:16px;
	color:#111;
	transition:$mytransition;
	&:before{
		padding:0;
	}
}

.totalprice-wrap{
  display: flex;  
  justify-content: space-between;
  align-items: center;
}

.label-price{
  min-width:100px;
  margin-bottom:0;
  font-size:18px;
}

.input-price{
  width:120px;
  // color: $red;
  color: $price;
  font-weight: 700;
  font-size: 26px;
  background:transparent;
  text-align: right;
  border:none;
}

.input-price:active,
.input-price:focus{
	border:none;
}

.passengers{
	position: relative;
}

.passengers__submenu{
	padding:15px 15px 30px 15px;
	background: #fff;
	position: absolute;
	top: calc(100% + 2px);
	left:0;
	width:100%;
	z-index:1;
	border-radius: $radius_sm;
	box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.08);
}

.passengers__submenu__title{
	// margin-bottom:25px;
	color: #f7b926;
	font-weight: 700;
}

.passengers__submenu__children label{
	margin-top:20px;
	margin-bottom:18px;
	display: block;
}

.count__passengers{
	display: flex;
	margin-left:10px;
	align-items: center;
}

.passengers__submenu__adults{
	display: flex;
	justify-content: space-between;
	align-items:center;
}

.passengers__sub__number{
	width:40px;
	text-align:center;
	font-size:24px;
	border:none;
	height:auto;
	padding:0 10px;
}

.children-group{
	font-size:16px;
	color: #000;
	line-height: 1.3;
	& span{
		display:block;
		color:#777;
		font-size:14px;
		line-height:1.3;
	}
}
.decrease, .increase{
	background:transparent;
	color: $orange3;
	font-size: 16px;
	border: 1px solid $orange3;
	border-radius: 50%;
	height: 36px;
	width: 36px;
	& .icon:before{
		padding:0;
	}
	&:focus{
		border: 1px solid $yellow;
	}
	&:active{
		border: 1px solid $yellow;
	}
	&:hover{
		border: 2px solid $yellow;
	}
	&:disabled{
		opacity:.4;
	}
	&:disabled:hover{
		border: 1px solid $yellow;
	}
}

.hidden{
	display: none !important;
}

.passengers__line{
	margin-bottom:15px;
	display: flex;
	align-items:center;
	justify-content: space-between;
}

.passengers__reset{
	border:1px solid $orange1;
	color: $orange1;
	width:auto;
	min-width:auto;
	margin-right:20px;
	&:hover{
		border:2px solid $orange1;
	}
}

.passengers__ok{
	border:2px solid transparent;
	transition: $mytransition;
}

.passengers__reset,
.passengers__ok{
	width:120px;
	padding:8px 12px;
	height:auto;
	min-width:auto;
	font-size:12px;
	font-weight: 700;
}

.passengers__buttons{
	display:flex;
	justify-content: flex-end;
	margin-top:20px;
}

@media screen and (min-width: 576px){
	.form-simple{
		padding: 40px;
	}
}

@media screen and (min-width: 768px){
	.form-simple{
		padding: 50px 60px;
		max-width: 100%;
	}
}

@media screen and (min-width: 992px){

}

@media screen and (min-width: 1200px){

}



/*--------------BOOKING STEPS GENERAL -----------------*/

.pagebooking{
	background:#fafafa;
}

.booking__h1{
	position:relative;
	z-index:0;
	display: inline-block;
}

#step1, #step2, #step3{
	position: relative;
	z-index:0;
}

.steps__container{
	margin:auto;
	padding-left:15px;
	padding-right:15px;
	overflow-x: hidden;
	// margin-top:60px;
}

input,
select,
textarea,
.label-checkbox:before,
.label-radio:before{
	// border: 1px solid #ddd;
	background: #fff;
	// background: #f2f2f2;
}

input:active,
select:active,
textarea:active,
input:focus,
select:focus,
textarea:focus,
{
	border: 1px solid $yellow;
}

.submit-2btns{
    display: flex;
    justify-content: space-between;
    margin-top:40px;
}

.nomobile{
	display: none;
}

@media screen and (min-width: 576px){
	.booking__h1:before {
		content:url('../img/points1.svg');
		position: absolute;
		z-index:-1;
		top:-100px;
		left:-150px;
	}
}

@media screen and (min-width: 768px){
	.nomobile{
		display: inline;
	}
}
/*--------------BOOKING STEP 1 -----------------*/

// .booking1{
// 	position:relative;
// 	&:before{
// 		content:"";
// 		position:absolute;
// 		display: block;
// 		top:-80px;
// 		bottom:-80px;
// 		right:-200px;
// 		left:-200px;
// 		// background: url('../img/bg-step1.svg') no-repeat;
// 		// background-size: auto 100%;
// 		z-index:-1;
// 		content:url('../img/bg-step1.svg');
// 		text-align:center;
// 		margin:auto;
// 	}
// }


/*--------------BOOKING STEP 2 -----------------*/

.booking2__group__content .row {
    align-items: flex-end;
}

.heading_rettangle{
	background: blue;
	display: inline-block;
	padding: 3px 10px;
	border-radius: 5px;
	color: white;
	margin-top: 50px;	
}

.heading_rettangle--1{
	background:#0095ee;
}

.heading_rettangle--2{
	background:#bb2525;
}


.ui-widget {
    font-size: 14px;
}

.label-help{
	display:flex;
	position:relative;
	align-items: flex-end;
}

.help{
	// position:relative;
	position:static;
	width: 22px;
	height: 22px;
	margin-left: 8px;
	margin-bottom:8px;
}


.help-icon{
	position:relative;
}

.help-icon:hover::after{
	background:$green;
	color:#fff;
	// transition:$mytransition;
}

.help-icon:hover:before{
	content: "";
	position: absolute;
	left: 7px;
	top: -10px;
	// bottom: 10px;
	border-bottom: none;
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	border-top: 5px solid #333;
}

.help-icon::after {
    content: "i";
    display: block;
    width: 22px;
    height: 22px;
    border: 1px solid $green;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    font-size: 14px;
    color: $green;
}

.help-content{
	position:absolute;
	font-size: 14px;
	background-color: #20282e;
	padding: 13px 18px;
	// bottom: calc(100% + 20px);
	bottom: calc(100% + 10px);
	top: auto;
	// width: 275px;
	width:100%;
	// left: calc(50% + 5px);
	left:0;
	// transform: translate(-50%,10px);
	z-index: 100;
	transition: .15s ease,opacity .25s ease;
	pointer-events: none;
	background: #333;
	padding: 10px 12px 12px;
	max-width: 300px;
	border-radius: $radius_sm;
	color:#f2f2f2;
}


.booking2__wrap{
	display:flex;
	flex-direction: column;
	box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.08);
	border-radius:30px;
	overflow:hidden;
	background: #f9f9f9;
	position:relative;
	z-index:1; //чтобы выше фона - точки
}

.booking2__info{
	width:100%;
	background: $yellow;
	align-self:flex-start;
}

.booking2__info img{
	display:none;
}

.booking__info__inner{
	padding:30px;
}

.booking__info__button{
	background:#605c5c;
	padding:30px 30px;
}

.booking2__info__title{
	opacity:.7;
	letter-spacing:.5px;
	font-size: 22px;
	margin-bottom:80px;
	margin-top:0;
	margin-bottom:40px;
}

.booking2-group-info{
	margin-bottom: 20px;
}

.note-price{
	font-size:16px;
	color: #222;
	position:relative;
	padding-left:30px;
	&:before{
		content:"**";
		position:absolute;
		left:0;
		top:0;
	}
}

.booking2__details{
	padding:50px 0;
}


// Шаг 2, информация Summary
.form-group-info{    
	margin-bottom: 20px; // стили для группы .label+.input 
}

.label{
	margin-bottom: 0;
	color:#4b4b4b;
	opacity:.8;
	font-size: 18px;
	font-weight:400;
}

.form-group-info-input{
	background:transparent;
	font-size: 20px;
	color:#222;
	padding-left:0;
	border:none;
}

.header-group-price{
	display: flex;
    justify-content: space-between;
    align-items: center;
	margin-top:20px;
	padding-top:20px;
	position:relative;
	&:before{
		content:"";
		position:absolute;
		top:0;
		left:0;
		height:2px;
		width:100%;
		background: #222;
		opacity:.4;
		display: block;
	}
}

.headerprice{
	font-size: 30px;
	font-weight: 700;
	letter-spacing:-1px;
	color: $price;
}

.aside-btn-back{
	color:#fff;
}

// END Шаг 2, информация Summary

.booking2__details__title{
	color: #222;
	letter-spacing:.5px;
	font-size: 22px;
	margin-top:0;
	margin-bottom:40px;
}

.booking2__group{
	background: #f9f9f9;
	box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.06);
	// background: #f0f0f0;
	border-radius: 30px;
	margin-top: 30px;
	// padding:40px;
	padding:40px 20px 30px 20px;
}

.booking2__h4 {
	padding:0;
	border-top-left-radius: 30px;
	border-top-right-radius: 30px;
	font-weight: bold;
	font-size: 16px;
	letter-spacing: 0.5px;
	color: #504D53;
	margin-top:0;
	margin-bottom:30px;
}

.form__messengers{
	display:flex;
	justify-content: space-between;
	align-items: center;
	height: 46px;
	margin-top:4px;
	width:100%;
	max-width:260px;
	& img{
		width:30px;
		margin-right:8px;
	}
	& .label-checkbox{
		padding: 0 0 0 30px;
	}
}

.form__messengers--err{
	border: 1px solid red;
	padding: 0 0 5px 10px;	
}


.form-group--social{
	display: flex;
	flex-direction: row;
	margin-bottom:0;
}

.form-group--social .checkbox-item{
	margin-bottom: 0;
}

.messenger-input {
  z-index: 3;
}

.structure-group{
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	max-width:700px;
}

.form-group-note{
	margin-top: 30px;
}

.btn--step2{
	width:auto;
}


@media screen and (min-width: 576px){
	.ui-widget {
	    font-size: 16px;
	}
	.form-group-info-input{
		font-size: 22px;
	}
	.headerprice{
		font-size: 30px;
	}
	.booking2__group{
		padding:40px;
	}
	.booking2__details{
		padding:50px 35px;
	}
	.help{
		position:relative;
	}
	.help-content{
		left: calc(80% + 5px);
		bottom:42px;
		transform: translate(-80%,10px);
		width: 275px;
	}
}


@media screen and (min-width: 768px){
	.steps__container{
		padding-left:60px;
		padding-right:60px;
	}
	.booking__info__inner{
		padding:60px;
	}
	.booking__info__button{
		padding:30px 60px;
	}
	.booking2__info__title,
	.booking2__details__title{
		font-size: 28px;
		margin-bottom:80px;
	}
	.booking2__h4{
		font-size: 18px;
	}
}

@media screen and (min-width: 992px){
	.structure-group{
		flex-direction: row;
	}
}


@media screen and (min-width: 1200px){
	.steps__container{
		max-width:1600px;
	}
	.booking2__wrap{
		flex-direction: row;
	}
	.booking2__info,
	.booking__info{
		width:33%;
		border-bottom-right-radius: 30px;
		overflow:hidden;
	}
	.booking2__details,
	.booking__details{
		width:67%;
	}
	.booking2__info img{
		display:block;
	}
}


/*--------------STEP3 SUMMARY-----------------*/

.summary__wrap{
	display:flex;
	flex-direction: column;
	// box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.08);
	border-radius:30px;
	overflow:hidden;
	// background: rgba(255,255,255,.98);
	background: #f2f2f2;
	padding:30px 20px;
}

.summary__group{
	margin-bottom:40px;
}

.summary-group{
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;
	& label{
		min-width:220px;
		margin:0;
		margin-right:40px;
	}
}

.summary__messengers{
	justify-content: flex-start;
}

.summary__whatsapp,
.summary__viber{
	margin-right:10px;
}

.summary-group-content {
    padding: 0 0 30px;
    // background: #f4f2f5;
    // margin-bottom: 30px;
}

#step3 input, #step3 textarea, .summary-text-input{
	padding:0;
	border:none;
	background:none;
	color: #111;
	font-size:20px;
}

#step3 input{
	height:30px;
}

#step3 .form-group{
	margin-bottom: 2rem;	
}

.summary-total-passengers,
.summary__price{
	// width:250px;
	display: flex;
	justify-content: space-between;
	margin-left:auto;
	padding-bottom:15px;
	padding-top:15px;
	// border-bottom:1px solid #b7b5b5;
	border-top:1px solid #b7b5b5;
	& label{
		font-size:20px;
	}
}

.summary-total-passengers label,
.summary__price label{
	min-width:auto;
	padding-right: 20px;
}

// .summary-total-passengers{
// 	padding-bottom:20px;
// 	padding-top:20px;
// 	border-bottom:1px solid #b7b5b5;
// 	border-top:1px solid #b7b5b5;
// }

.summary__price{
	margin-bottom:60px;
}

.btn--step3{
	max-width:200px;
}

.summary__price-input{
	font-size:30px;
	font-weight: 700;
	letter-spacing: -1px;
	color: $price;
}

@media screen and (min-width: 576px){
	.summary__wrap{
		padding:40px 40px 60px 40px;
	}
}

@media screen and (min-width: 768px){
.summary-group-content {
    margin-bottom:0;
}

}

@media screen and (min-width: 1200px){
	.summary-group{
		flex-direction:row;
		align-items:center;
		margin-bottom: 10px;
	}
// .summary-group-content {
//     height:100%;
//     margin-bottom:0;
// }

}




/* ////////////////////////////////////////// */
/*-------------- PAGE EXCURSIONS--------------*/
/* ////////////////////////////////////////// */


@import "./partials/excursions";

.ekskursii::before {
    content: url('../img/points1.svg');
    position: absolute;
    z-index: -1;
    top: 300px;
    left: auto;
    right: 80px;
    height: 200px;
    width: 200px;
    display: block;
    -webkit-border-radius: 100%;
    border-radius: 100%;
    overflow: hidden;
}


/* ////////////////////////////////////////// */
/*-------------- PAGE ONE EXCURSION--------------*/
/* ////////////////////////////////////////// */

.one-excurs{
	overflow: hidden;
}

.one-excurs__content{
	padding-bottom:0;
}

.one-excurs__img{
	border-radius: $radius;
	margin-bottom:40px;
}

.one-excurs__h2:before{
	display: none;
}

.exc__title-addition{
	display: block;
	margin-top:20px;
	margin-bottom:20px;
	// font-family: "Source Sans Pro", sans-serif;
	font-weight: 400;
	font-size: 22px;
	color: lighten($textcolor, 10);
}

// .btn--allexcurs{
// 	padding-left:0;
// 	margin-bottom:20px;
// }

.btn--allexcurs{
	color:#555;
	padding-left:0;
	margin-bottom:20px;
    transition:$mytransition;
	&:after{
		content:"→";
		font-size:20px;
		padding-left:8px;
		transition:$mytransition;
	}
	&:hover{
		color:#ec9f0b;
		transition:$mytransition;
	}
	&:hover:after{
		padding-right:4px;
		padding-left:4px;
		transition:$mytransition;
	}
}

.one-excurs__duration{
	position: relative;
	padding:24px 0;
	margin-bottom:40px;
	font-size: 18px;
	letter-spacing: 1px;
	font-weight: 400;
	color:lighten($textcolor, 10);
	border-bottom:1px solid $orange1;
	& span{
		font-weight: 700;
		font-size:20px;
		padding-left:3px;
	}
}


.excprices{
	padding-top:130px;
	background: url('../img/bg-taxi.svg') 0 0 no-repeat;
	background-size: 100% auto;
	position:relative;
}

.exc__subtitle {
    font-size: 26px;
    max-width: 600px;
    margin-bottom: 80px;
}


@media screen and (min-width: 576px){
	.excprices{
		padding-top:200px;
	}
}

@media screen and (min-width: 768px){
.excprices{
	padding-top: 220px;
	&:before{
		content:"";
		position:absolute;
		left:0;
		top:0;
		// bottom:-660px;
		display: block;
		width:100%;
		height:660px;
		// background: url('../img/excprices-wave-992.svg') top center no-repeat;
	}
}
}

@media screen and (min-width: 992px){
	.excprices{
		padding-top:200px;
	}
}

@media screen and (min-width: 1200px){
	.excprices{
		padding-top:260px;
		background-position:top center;
	}
}


.prices__h2{
	text-align: left;
}

.prices__item{
	border-radius: $radius;
	overflow:hidden;
	transition: $mytransition;
	position:relative;
	z-index: 0;
	margin:auto;
	margin-bottom:40px;
	text-align:center;
	max-width:300px;
	// color: #693b88;
	box-shadow: 0 0 20px rgba(0,0,0,0.1);
}

.prices__header{
	position: relative;
	z-index: 0;
	font-size:22px;
	font-weight: 600;
	line-height:1.3;
	padding:30px;
	&:after{
		content:"";
		position:absolute;
		left:0;
		top:100%;
		right:0;
		width:100%;
		height:32px;
	}
}

.prices__header--st1{
	background: $yellow;
	&:after{
		background: url('../img/price-card-wave1.svg') top center no-repeat;
		background-size:100% auto;
	}
}

.prices__header--st2{
	background:  #FFEEB1;
	&:after{
		background: url('../img/price-card-wave2.svg') top center no-repeat;
		background-size:100% auto;
	}
}

.prices__info{
	display: flex;
	flex-direction: column;
	padding:60px 30px 50px 30px;
	// height:220px;
	justify-content:space-between;
	align-items:center;
}

.prices__npersons{
	font-size:22px;
	margin-bottom:10px;
	& span{
		font-weight: 700;
		font-size:24px;
		color:darken($textcolor, 20);
	}
}

.bookingexc__price{
	width:50%;
	padding: 0;
	text-align: right;
}

.prices__price{
	color: $orange1;
	font-size: 32px;
	font-weight: 700;
	margin-bottom: 20px;
}

.prices__btn{
	// background: $yellow;
	width:auto;
	min-width:auto;
	transition: $mytransition;
	height:auto;
	width:auto;
	padding:8px 18px;
	font-size:12px;
	// background:transparent;
	// border:1px solid $yellow;
	&:hover{
		transition: $mytransition;
	}
}

@media screen and (min-width: 576px){

}

@media screen and (min-width: 768px){
	.prices__item{
		margin-left:8px;
		margin-right:8px;
		margin-top:8px;
	}
}

@media screen and (min-width: 992px){
	.one-excurs {
	    margin-top: 100px;
	}
}

@media screen and (min-width: 1200px){
	.one-excurs {
	    margin-top: 0;
	}
	.one-excurs__inner{
		width:960px;
	}
	.nextexc__link{
		&:hover{
			letter-spacing: 5px;
			transition:$mytransition;
		}
	}
}

@media screen and (min-width: 1921px){
	.excprices {
	    background: url('../img/bg-taxi-2560.svg') 0 0 no-repeat;
	}
}


/*-------------- END PAGE ONE EXCURSION--------------*/

/* ////////////////////////////////////////// */
/*-------------- BOOKING EXCURSION--------------*/
/* ////////////////////////////////////////// */
.invaliddata{
	text-align:center;
}

.empty__btn{
	margin:auto;
	margin-top: 30px;
	width:auto;
}

.excursions__btn{
	width:auto;
	min-width:200px;
}

/*-------------- END BOOKING EXCURSION--------------*/


/* ////////////////////////////////////////// */
/*-------------- FOOTER--------------*/
/* ////////////////////////////////////////// */

.footer{
	text-align: left;
	background: transparent;
	// padding:60px 0 0 0;
	padding:0;
	font-size:16px;
	// color: $textvioletlight;
	letter-spacing: 0.5;
	position:relative;
	&:before{
		content:"";
		display: block;
		// position:absolute;
		width:100%;
		// top:-158px;
		top:0;
		left:0;
		height:45px;
		background: #fff url('../img/bg-footer.svg') top center no-repeat;
		background-size:100% 100%;
	}

	& a{
		color:$textcolor;
		&:hover{
			opacity:0.5;
		}
	}

	&__phone{
		font-size:20px;
		margin-top: 10px;
		margin-bottom:5px;	
	}

	&__top{
		padding-top:40px;
		padding-bottom:20px;
		// text-align:center;
		background: $yellow;
	}

	&__icons{
		margin:0;
		margin-top:10px;
		justify-content: flex-start;
	}

	&__bottom{
		font-size: 14px;
		text-align: right;
		padding:10px 0px;
		border-top:1px solid rgba(255,255,255,.2);
		background: $yellow;
	}

	// &__privacy{
	// 	margin-bottom: 20px;
	// }
}

.pagebooking footer:before{
	background-color: #fafafa;
}

.footer__services{
	display: flex;
	flex-direction: column;
	& span:not(:last-child){
		margin-bottom: 5px;
	}
}


.footer__row{
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	flex-wrap:wrap;
	flex-basis:200px;
}

.footer__col{
	margin-bottom: 40px;
}

.col-left span{
	display: block;
}

.footer__title{
	font-size:18px;
	// color:#fff;
	font-weight:600;
}

.footer__icons{
	width:100%;
}

.footer__icons img{
	padding:0;
	margin-right:15px;
}

.footer__logo{
	width:140px;
	margin-bottom: 10px;
}

.menu--footer a{
	margin-bottom:5px;
}

.footer__bottom__wrapper {
	display:flex;
    flex-direction: column;
}

@media screen and (min-width: 576px){
	.footer:before{
		height:80px;
	}
}
@media screen and (min-width: 768px){
	.footer{
		text-align: left;
		// padding-top:220px; //158px - высота bg, + padding
		&:before{
			background: #fff url('../img/bg-footer.svg') top center no-repeat;
			background-size:100% 100%;
		}
	}
	.footer__row{
		flex-direction: row;
	}
	.footer__bottom__wrapper{
		flex-direction: row;
		justify-content: space-between;
	}
}

@media screen and (min-width: 992px){
	.footer:before{
		height:155px;
	}
}

/* ////////////////////////////////////////// */
/*-------------- Pagina PRIVACY--------------*/
/* ////////////////////////////////////////// */

.body-privacy h2::before {
    content: "";
}

.body-privacy {
	& h1{
		font-size:28px;
	}
	& h2{
		font-size:24px;
		margin-top: 80px;
	}
	& h3{
		font-size:20px;
		margin-top: 50px;
		margin-bottom: 30px;
	}
	& h4{
		font-size:16px;
		margin-top: 40px;
	}
}

@media screen and (min-width: 576px){
	.body-privacy {
		& h1{
			font-size:36px;
		}
		& h2{
			font-size:30px;
		}
	}
}


/* ////////////////////////////////////////// */
/*-------------- POPUP--------------*/
/* ////////////////////////////////////////// */

.modal {
    z-index: 2000;
    color: $textgrey;
    overflow: auto;
    position: fixed;
    outline: 0;
    text-align:center;
    background:rgba(0,0,0,0.4);
    display: flex;
    align-items: center;
}

.modal, .modal-backdrop {
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
}

.modal.in .modal-dialog {
    transform: translate(0,0);
}

.modal-dialog {
    width: 600px;
    max-width: 80%;
    margin: 80px auto;
    position: relative;
    background-color: #fff;
    border-radius: $radius_sm;
    box-shadow: 0 5px 30px rgba(0,0,0,.1);
}

.modal__content {
    position: relative;
    padding: 40px 20px;
    outline: 0;
}

.modal__content {
    background-clip: padding-box;
}

.modal__title{
	font-weight: 700;
	font-size:24px;
	line-height:1.3;
	letter-spacing: 1px;
	text-transform: uppercase;
	margin-bottom:20px;
}

.modal__message{
	margin-bottom: 30px;
	font-size:14px;
	line-height:1.3;
	text-align: left;
}

.modal__icons{
	display:flex;
	justify-content: flex-end;
	width:90px;
}

.modal__phone{
	display:flex;
}

.footer a.text-red{
	color: #fc2020;
}

.modal__icons > a > img{
	width:20px;
	margin-left:8px;
}

.modal-error{
	color: red;
}

.modal-error--default{
	margin-bottom:10px;
}

.modal__message--error {
	font-size:20px;
	color: red;
}

// .modal__title--error {
// 	color: red;
// }

.btn-error{
	background:#f20404;
	transition: $mytransition;
	&:hover{
		background:#bb0505;
		transition: $mytransition;
	}
}

.modal__title--success,
.modal__message--success {
	color: green;
}

.modal__message--success{
	text-align: left;
}

.modal__message--success .text-red{
	font-weight:600;
}

.btn-success{
	background:green;
	transition: $mytransition;
	&:hover{
		background: #036803;
		transition: $mytransition;
	}
}

.modal__icon-close{
	color: #6d666f;
	font-size:36px;
	width:36px;
	height:36px;
	&:before{
		padding:0;
	}
}
.modal__btn{
	width:100%;
	max-width:200px;
	display: block;
	margin:auto;
}
.modal__btn-icon {
    position: absolute;
    top: -14px;
    right: -14px;
    background: transparent;
    z-index: 9999;
}


@media screen and (min-width: 768px){
	.modal__content {
	    padding: 40px;
	}
	.modal__message{
		font-size:16px;
		text-align:center;
	}
	.modal__message--success{
		text-align: left;
	}
	.modal__phone{
		justify-content:center;
	}
	.modal-dialog{
		margin-top:0;
		margin-bottom:10%;

	}
}
